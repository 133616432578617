import { ViewportScroller } from '@angular/common';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageProvider } from '@isa/core/storage';

/**
 * Returns a function that restores the scroll position from session storage.
 *
 * The returned function waits for an optional delay to ensure the DOM is ready before scrolling.
 *
 * @returns A function accepting an optional delay (in milliseconds) and restoring scroll position.
 */
export function injectRestoreScrollPosition(): () => Promise<void> {
  const router = inject(Router);
  const viewportScroller = inject(ViewportScroller);
  const sessionStorage = inject(SessionStorageProvider);

  return async (delay = 0) => {
    const url = router.url;
    const position = await sessionStorage.get(url);

    if (position) {
      // wait for the next tick to ensure the DOM is ready
      await new Promise((r) => setTimeout(r, delay));
      sessionStorage.clear(url);
      viewportScroller.scrollToPosition(position as [number, number]);
    }
  };
}
