import { ActivatedRoute } from '@angular/router';

/**
 * Recursively retrieves the deepest activated route.
 *
 * @param route - The starting ActivatedRoute instance.
 * @returns The deepest ActivatedRoute found.
 */
export const getDeepestActivatedRoute = (route: ActivatedRoute): ActivatedRoute => {
  while (route.firstChild) {
    route = route.firstChild;
  }
  return route;
};
