import { ViewportScroller } from '@angular/common';
import { EnvironmentProviders, inject, provideEnvironmentInitializer } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SessionStorageProvider } from '@isa/core/storage';
import { getDeepestActivatedRoute } from './utils/get-deepest-activated-route';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Provides an environment initializer that restores scroll position upon navigation and page unload.
 *
 * Listens to router navigation events and the window's beforeunload event to store the current scroll position.
 *
 * @returns EnvironmentProviders for scroll position restoration.
 */
export function provideScrollPositionRestoration(): EnvironmentProviders {
  return provideEnvironmentInitializer(() => {
    const router = inject(Router);
    const viewportScroller = inject(ViewportScroller);
    const sessionStorage = inject(SessionStorageProvider);

    function storeScrollPosition() {
      const url = router.url;
      const route = getDeepestActivatedRoute(router.routerState.root);

      if (route.snapshot.data?.['scrollPositionRestoration']) {
        sessionStorage.set(url, viewportScroller.getScrollPosition());
      }
    }

    if (window) {
      window.addEventListener('beforeunload', () => {
        storeScrollPosition();
      });
    }

    router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationStart) {
        storeScrollPosition();
      }
    });
  });
}
